<template>
<div class="charge-plan" :style="!handle?'min-height:320rem;padding-bottom:0;':''">
  <el-descriptions :column="1" class="plan-desc" label-class-name="plan-label" content-class-name="plan-content">
    <el-descriptions-item label="收费学年">{{ data.school_year_name }}</el-descriptions-item>
    <el-descriptions-item label="收费日期">{{ data.charge_date }}</el-descriptions-item>
    <el-descriptions-item label="收费科目组合">{{ data.fee_combination_name }}</el-descriptions-item>
    <el-descriptions-item label="应付金额">{{ compute.payable_price }}</el-descriptions-item>
    <el-descriptions-item label="减免金额">{{ compute.derate }}</el-descriptions-item>
    <el-descriptions-item label="实付金额">{{ compute.price }}</el-descriptions-item>
  </el-descriptions>
  <div class="plan-bottom" v-if="handle">
    <a href="javascript:;" @click="()=>$emit('on-delete',this.data)" class="plan-bottom-delete">删除</a>
    <a href="javascript:;" @click="()=>$emit('on-edit',this.data)" class="plan-bottom-edit">编辑</a>
  </div>
</div>
</template>

<script>
export default {
  name: "plan",
  props:{
    data:{
      type:Object,
      default(){
        return {}
      }
    },
    handle:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    compute(){
      let res = {};
      if (this.handle){
        res.derate =  (Number(this.data.price)-Number(this.data.actual_price)).toFixed(2); // 减免金额
        res.price = Number(this.data.actual_price).toFixed(2); // 实付金额
        res.payable_price = this.data.price; // 应付金额
      }else{
        res.derate = this.data.reduction_price;
        res.price = this.data.actual_price;
        res.payable_price = this.data.payable_price;
      }
      return res;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_setting.scss";
  $border: #DBDDE4;
  .charge-plan{
    height: 100%;
    background-color: #F9FAFD;
    display: inline-block;
    width:320rem;
    min-height: 380rem;
    position: relative;
    padding:0 30rem 54rem;
    .plan-desc{
      padding-top: 32rem;
      ::v-deep .el-descriptions__body{
        background-color: transparent;
      }
    }
    ::v-deep .el-descriptions-item__container{
      margin-bottom: 11rem;
      .plan-label{
        width:100rem;
        justify-content: end;
        color:#222;
        font-size: 16rem;
        margin-right: 40rem;
        line-height: 22rem;
      }
      .plan-content{
        color: #1E3AA2;
        font-size: 16rem;
        line-height: 22rem;
        flex:1;
      }
    }
    .plan-bottom{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      line-height: 54rem;
      border-top: 1px solid $border;
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 16rem;
      &-delete{
        flex:1;
        border-right: 1px solid $border;
        color: $error;
      }
      &-edit{
        flex:1;
        color:$primary;
      }
    }
  }
</style>
